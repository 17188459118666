import React       from 'react';
import Img         from 'gatsby-image';
import { graphql } from 'gatsby';

import CallToAction  from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Carousel      from '@interness/web-core/src/components/media/Carousel/Carousel';
import ImageWithText from '@interness/web-core/src/components/media/ImageWithText/ImageWithText';

import Articles        from '../components/Articles/Articles';
import MemberSearchBar from '../components/MemberSearch/MemberSearchBar';

function IndexPage(props) {
  return (
    <>
      <Carousel>
        {props.data.headerImages.media.map(image =>
          <ImageWithText key={image.file.localFile.name}>
            <div className='int-text'>
              <b>Freier Verband Deutscher Heilpraktiker e.V.</b>
              <p style={{ fontWeight: 'bold' }}>Berufs- und Fachverband.</p>
              <p>Mitglied im <b>D</b>achverband <b>D</b>eutscher <b>H</b>eilpraktikerverbände e.V.</p>
              <p>Mitglied der Arzneimittelkommission (AMK) im DDH</p>
              <p>Mitglied der Gebühren- und Sachverständigenkommission (GSK) im DDH</p>
            </div>
            <Img fluid={image.file.localFile.childImageSharp.fluid}/>
          </ImageWithText>
        )}
      </Carousel>
      <div style={{ marginTop: '-10px' }}>
        <MemberSearchBar/>
      </div>
      <Wrapper>
        <Spacer/>
        <div style={{ color: '#172C7F', fontStyle: 'italic' }}>
          <Heading subtitle={'Ihr Berufs- und Bundesverband'}>Freier Verband Deutscher Heilpraktiker
            e.V.</Heading>
          <p style={{ fontSize: '1.2rem', fontWeight: 'bold', textAlign: 'center', marginBottom: '20px' }}>Herzlich
            Willkommen auf unserer Homepage</p>
          <div style={{ fontSize: '1.1rem', maxWidth: '700px', margin: 'auto', textAlign: 'justify' }}>
            <p>Willkommen beim Freien Verband Deutscher Heilpraktiker e.V. (FVDH) – einem großen, bundesweit aktiven Heilpraktiker-Berufsverband, der sich den berufs- und medizinal-politischen Aufgaben - auch als Mitglied im Dachverband Deutscher Heilpraktiker-Verbände (DDH) - verschrieben hat.</p>
            <p>Dazu gehören die Erhaltung der Rechtsgrundlage / Besitzstandswahrung für den HP-Berufsstand durch Kontaktpflege zu Gesundheitsämtern, sowie Behörden und Regierungen auf Länder- und Bundesebene.</p>
            <p>Über unsere Geschäftsstelle in Münster betreuen wir unsere Mitglieder und stellen sicher, dass jeder Heilpraktiker die Unterstützung erhält, die er für seine berufliche Entwicklung benötigt.</p>
            <p>Unsere Fach-Fortbildungen durch qualifizierte Lehrbeauftragte sind darauf ausgerichtet, Ihr Wissen und Ihre Kompetenzen in der Naturheilkunde zu erweitern, sodass Sie stets auf dem neuesten Stand der Praxis und Forschung bleiben.</p>
            <p>Unsere Mitglieder genießen exklusiven Zugang zu einer Vielzahl von Informationen zu berufsrechtlichen Vorgaben und den neuesten Entwicklungen in der Naturheilkunde.</p>
            <p>Zudem ist der FVDH Mitglied und einer der Träger der Arzneimittelkommission (AMK), die uns regelmäßig über den aktuellen Stand der medizinischen Anwendungsmöglichkeiten hält.</p>
            <p>Schließen Sie sich uns an und werden Sie Teil einer Gemeinschaft, die sich für die Förderung und Anerkennung der Naturheilkunde und des Heilpraktiker-Berufes als freiberufliche Therapeuten in Deutschland und darüber hinaus einsetzt.</p>
            <p>Mit Ihrer Mitgliedschaft beim FVDH setzen Sie ein starkes Zeichen für die Bedeutung der Naturheilkunde und unterstützen eine Zukunft, in der integrative und ganzheitliche Heilmethoden weiterhin einen festen Platz in der Gesundheitsversorgung einnehmen.</p>
            <p>Treten Sie heute bei und gestalten Sie mit uns die Zukunft unseres unabhängigen und freien Therapeuten-Berufes.</p>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    headerImages: directusMediaCollection(name: {eq: "home"}) {
      media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1980, maxHeight: 750, cropFocus: CENTER, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;